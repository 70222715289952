import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import HC_Exporting from 'highcharts/modules/exporting'
import HC_ExportingOffline from 'highcharts/modules/offline-exporting';
import { Paper } from '@mui/material';

// HC_Exporting(Highcharts)
// HC_ExportingOffline(Highcharts)

function colorGradient(fadeFraction, rgbColor1, rgbColor2, rgbColor3) {
    var color1 = rgbColor1;
    var color2 = rgbColor2;
    var fade = fadeFraction;

    // Do we have 3 colors for the gradient? Need to adjust the params.
    if (rgbColor3) {
      fade = fade * 2;

      // Find which interval to use and adjust the fade percentage
      if (fade >= 1) {
        fade -= 1;
        color1 = rgbColor2;
        color2 = rgbColor3;
      }
    }

    var diffRed = color2.red - color1.red;
    var diffGreen = color2.green - color1.green;
    var diffBlue = color2.blue - color1.blue;

    var gradient = {
      red: parseInt(Math.floor(color1.red + (diffRed * fade)), 10),
      green: parseInt(Math.floor(color1.green + (diffGreen * fade)), 10),
      blue: parseInt(Math.floor(color1.blue + (diffBlue * fade)), 10),
    };

	const outColor = 'rgb(' + gradient.red + ',' + gradient.green + ',' + gradient.blue + ')'
    return outColor;
  }

const ColumnChart = (props) => {
    const {
        title = '',
		unit = '',
        xAxis = [],
        series = []
    } = props

	const maxVal = Math.max(...series[0].data)
	const minVal = Math.abs(Math.min(...series[0].data))
	console.log('series', series)
	
    const options = {
        title: {
            text: title
        },
		plotOptions: {
			column: {
				borderWidth: 0
			},
		},
        chart: {
            type: 'column',
			backgroundColor: 'transparent',
        },
        xAxis: {
            categories: xAxis.map((i) => {
				if (i.slice(5,10) == '01-01') {
					return i.slice(0, 4)
				} else {
					return i
				}
			}),
			lineWidth: 0,
			// categories: xAxis,
			labels: {
				step: 12,
				rotation: -45,
				style: {color: 'white'},
			},
			// visible: false,
        },
        yAxis: {
            title: {
                text: unit
            },
			gridLineWidth: 0,
			tickInterval: 1,
			ceiling: maxVal,
			floor: -1 * minVal,
			labels: {
				formatter: function () {
					if (this.value % 10 == 0) {
						return this.value
					}
				},
				style: {color: 'white'},
			},
        },
		series: [{showInLegend: false, name: '', data: series[0].data.map((i) => {
			const lowColor = {red: 0, green: 0, blue: 255}
			const midColor = {red: 0, green: 128, blue: 0}
			const highColor = {red: 255, green: 0, blue: 0}
			const colorRatio = Math.abs((i + minVal) / (maxVal + minVal))
			const out = colorGradient(colorRatio, lowColor, midColor, highColor)
			// const out = colorGradient(colorRatio, lowColor, highColor)

			return {y: i, color: out}
		})}],
        credits: {
            enabled: false
        },
        exporting: {
			enabled: false
        }
    }

    return (
        // <div style={{width: '90vw', height: '80vh'}}>
            // <div style={{height: '100%', width: '100%', backgroundColor: '#666666', padding: '2%'}}>
            <div style={{height: '100%', width: '100%', margin: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                <HighchartsReact 
                    highcharts={Highcharts}
                    options={options}
                    containerProps={{style: {height: '30%', width: '100%', marginBottom: '-40px'}}}
                />
            </div>
                
            // </div>
        // </div>
    )
}

export default ColumnChart;
